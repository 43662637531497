.footer-wrapper {
  background-color: $sidebar-color;

  .footer-signup {
    background-color: $sidebarSecondaryColor;
    padding: 20px 0px;
  }

  .footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
    padding-bottom: 0;
  }
  .footer-img {
    max-width: 316px;
  }
  .footer-nav {
    text-align: center;
    justify-content: space-evenly;
    align-items: center;   
  }
  // .ad_inner_placeholder{
  //   @media (max-width:500px) {
  //       // height: 100px;
  //   }
  // }
}

form.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'roboto', sans-serif;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin: 0 auto;
  color: $text;
  border-radius: 10px;
  background-color: #fff;
  h2 {
    padding-top: 1em;
    text-align: center;
    position: relative;
    font-size: 1.8rem;
    &::after{
      content:'';
      bottom:-5px;
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $navbar-color;
    }
  }

  md-input-container {margin: 5px auto 0px}

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    font: inherit;
    transition: transform 0.3s ease-in-out,
    font-size 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  }

  input,
  textarea {
    font: inherit;
    font-size: 1.2em;
    margin: 10px 25px 10px;
    width: $width;
    display: block;
    border: none;
    padding: 25px 0 10px;
    border-bottom: solid 1px $base;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 96%, $base 4%);
    background-position: -$width 0;
    background-size: $width 100%;
    background-repeat: no-repeat;
    transition: background 0.3s $cubic;
    resize: none;
    overflow: hidden;
    &:focus::-webkit-input-placeholder {
      color: $base;
    }
    &:focus,
    &:valid {
      box-shadow: none;
      outline: none;
      background-position: 0 0;
      &::-webkit-input-placeholder {
        font-size: 0.8em;
        transform: translateY(-20px);
        visibility: visible !important;
        opacity: 1;
      }
    }
  }

  input[type="email"] {
    background: linear-gradient(to bottom, rgba(255,255,255,0) 96%, $base 4%);
    background-position: -400px 0;
    background-repeat: no-repeat;
    transition: border-bottom 0.3s ease, background 0.3s ease;
    &~span.validation-text {
      position: absolute;
      visibility: hidden;
      font-family: 'roboto', sans-serif;
      font-size: 0.6em;
      width: $width;
      margin-top:5px;
      color: white;
      transition: color 0.3s ease-in-out;
    }
    &:not([value=""]) {
      &::-webkit-input-placeholder {
        font-size: 0.8em;
        transform: translateY(-20px);
        visibility: visible !important;
        opacity: 1;
      }
      &:focus::-webkit-input-placeholder {
        color: $base;
      }
      &:focus:not(:valid)::-webkit-input-placeholder {
        color: $invalid;
      }
    }
    &:focus {
      background-position: 0 0;
      &:not(:valid):not([value=""]) {
        border-bottom: solid 1px $invalid;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 96%, $invalid 4%);
      }
    }
    &:not(:focus):not(:valid):not([value=""]) {
      &::-webkit-input-placeholder {
        color: $invalid;
      }
      border-bottom: solid 1px $invalid;
      background-position: 0 0 ;
      background: linear-gradient(to bottom, rgba(255,255,255,0) 96%, $invalid 4%);
      &~span.validation-text {
        visibility: visible;
        color: $invalid;
      }
    }
  }

  .flex {
    display: flex;
    align-items: stretch;
    textarea {
      line-height: 120%;
    }
  }


  button.send-message {
    // background-color: $navbar-color!important;
    color: #fff;
    // border-color: rgba(60,180,153,0.7)!important;
    background-clip: padding-box;
    padding: .2em 1.5em;
    // border: 4px solid;
    font-size: 1.2em;
    text-transform: none;
    span{
      margin: 5px;
    }
    margin-top: 15px;
    margin-bottom: 20px;
    margin-right: -10px;
  }
  .send-message:hover {
    opacity: 0.9;
    border:1px solid #ffac02;
  }

  .form-control:focus {
    background-color: transparent;
  }
}
.google-auto-placed {
  display: none !important;
}
.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  background-color: $sidebarColor;
  z-index: 1;
  .sidenav-bg {
    color: #203736 !important;
  }

  .sidenav-bg:hover {
    background-color: #c0f1ea;
    a {
      color: #15866e !important;
    }
  }
  .sidenav-bg .active {
    background-color: #15866e;
    color: #fff;
    &:hover {
      color: #fff !important;
    }
  }
}

.logo {
  max-height: 30px;
}

.sideBarText {
  display: flex;
  align-items: center;
  color: white !important;
}

.sideBarsubText {
  display: flex;
  align-items: center;
  color: $sidebar-subtext;
  padding-left: 45px;
}

.logo i {
  background: url('/assets/images/logos/LM-sprite.png') -0px -32px;
  background-repeat: no-repeat;
  background-size: 729px;
  display: inline-block;
  height: 30px;
  width: 150px;
}

.logo-sml {
  background: url('/assets/images/logos/LM-sprite.png') -0px -174px;
  background-repeat: no-repeat;
  display: inline-block;
  height: 76px;
  width: 76px;
  zoom: 0.4;
}

.signinup {
  font-size: 1em;
  color: whitesmoke;
  .icon {
    background: url('/assets/images/logos/LM-sprite.png') -429px -208px;
    background-repeat: no-repeat;
    background-size: 600px;
    display: block;
    height: 24px;
    width: 26px;
    margin: 0 auto;
  }
}

.mobile-nav {
  margin: 8px;
  margin: 8px;
  display: inline-block;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 6px;
}

.sidebarNav {
  position: fixed;
  z-index: 999;
  width: 280px;
  .sideBarsubText:hover {
    color: #fff;
  }
  .nav-icon {
    background: url('/assets/images/logos/LM-sprite.png');
    background-size: 720px;
    height: 25px;
    width: 20px;
    display: inline-block;
    padding-left: 35px;
    font-weight: normal;
    color: #182829;
  }
  .ruler {
    background-position: -3px -644px;
  }
  .weight-scales {
    background-position: -35px -644px;
  }
  .calipers {
    background-position: -68px -644px;
  }
  .scales {
    background-position: -341px -644px;
  }
  .chart {
    background-position: -37px -158px;
  }
  .discussion {
    background-position: -1px -145px;
    background-size: 675px !important;
  }
  .calculator {
    background-position: -1163px -654px;
    background-size: 766px !important;
  }
  .sub-nav {
    padding: 0px 32px;
  }
}

// @include media-breakpoint-down(lg) {
  @media (max-width:992px) {
    .sidebarArea {
      position: fixed;
      height: 100%;
      overflow: auto;
      top: 0;
      z-index: 1000;
      // margin-top: 0;
      margin-left: -$sidebarWidth;
      transition: 0.2s ease-in;
      &.showSidebar {
        margin-left: 0px;
      }
    }
  }
  
// }

.footer-divider {
  position: absolute;
  bottom: 64px;
  width: 100%;
}

.footer-sidebar {
  // background-color: #528b7f;
  // display: flex;
  position: fixed;
  bottom: 8%;
  margin-bottom: 40px;
  width: $sidebarWidth;
  a {
    font-size: 0.8em;
    // padding: 10px 0 10px 20px;
  }
}
.sidebar-footer {
  position: fixed;
  bottom: calc(100vh - 80%);
  left: 40px;
}
img.lines {
  width: 200px;
}
.footer-sidebar-signup {
  background-color: $sidebarSecondaryColor;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: $sidebarWidth;
  justify-content: center;
  height: 76px;
  .signup-btn {
    width: 174px;
    height: 45px;
    border: 1px solid #ffffff;
    border-radius: 30px;
    background: transparent;

    // font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    padding-top: 10px;
    text-align: center;
    &:hover {
      background-color: $navbar-color;
      border: 1px solid #fff;
      color: #fff;
    }
  }
}

.footerText {
  text-decoration: none;
  color: $sidebar-subtext;
  margin: 4px 0;
  padding: 10px 20px;
}

.footerText:hover {
  color: $sidebar-subtext;
}

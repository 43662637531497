.calcCard {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .card-title {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 50%;
      height: 2px;
      background-color: $navbar-color;
    }
  }

  ul li {
    position: relative;
    list-style: none;

    &::after {
      content: '';
      height: 7px;
      width: 7px;
      background: $navbar-color;
      transform: rotate(45deg);
      position: absolute;
      top: 10px;
      left: -25px;
    }
  }
}

.calc-btns {
  background-color: $navbar-color;
  border-radius: 20px;
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

  &:hover {
    background-color: $navbar-color;
    border-color: transparent;
    animation: pulse 1s;
    transition: 0.3s;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.start-now {
  background-color: $navbar-color !important;
  color: #fff;
  border-color: rgba(243, 186, 70, 0.8) !important;
  background-clip: padding-box;
  border: 2px solid;
  margin: 0 auto;
  text-transform: none;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

.start-now:hover {
  opacity: 0.9;
}

.start-now:active {
  opacity: 1;
}

.height-predictor {
  background-color: #fff;
  min-height: 100vh;
  // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  .form-group {
    // align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 2rem;

    span {
      // color: $calculator-green-color;
      font-weight: bold;
    }

    label {
      margin: auto;
    }
  }

  h1 {
    font-weight: 600;
  }

  .slider {
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-color: $calculator-green-color;
    background-color: $calculator-green-color;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    background: $calculator-green-color;
    cursor: pointer;
  }

  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: #c0f1ea;
  }
}

.panel {
  border: 0;
  // border-bottom: 1px solid $medium-gray;
  // box-shadow: none;
}

.panel-default>.panel-heading {
  // background-color: $white-color;
  border-color: #fff;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-title {
  // font-size: $font-size-h5;
}

.panel-title a:hover,
.panel-title a:focus {
  text-decoration: none;
}

.collapse-hover {
  display: block;
  height: 0px;
  visibility: visible;
  overflow: hidden;
}

.panel-title a:hover,
.panel-title a:focus {
  // color: $default-states-color;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
  box-shadow: inset 0 7px 10px -7px rgba(0, 0, 0, 0.14);
}

.panel-heading {
  padding: 0;

  .caret {
    float: right;
    margin-top: 12px;
    // margin-right: $padding-default-horizontal;
  }

  a {
    // padding: $padding-default-vertical 0;
    display: block;
    width: 100%;

    .content-full-width & {
      // padding: $padding-default-vertical $padding-default-horizontal;
    }
  }
}

.results-section {
  background-color: $navbar-color;
  border-top: 1px solid #e8d6c1;
  position: relative;

  h1 {
    font-size: 2.5em;
    font-weight: 100;
    color: #fff;
  }

  h2 {
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 2em;
  }

  h3 {
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 2em;
  }

  .results-values {
    h6 {
      font-size: 1rem;
      font-weight: 500;
      color: #fff;
    }

    span {
      color: #dbfffa;
      font-size: 0.9rem;
      font-weight: 400;
    }
  }

  .save-results {
    margin-top: -45px;
  }

  .save-changes {
    background-color: $navbar-color !important;
    color: #fff;
    border-color: #fbc169 !important;
    padding: 0.2em 1.5em;
    border: 3px solid;
    font-size: 1.2em;
    text-transform: none;
    height: 50px;
  }

  .save-changes:hover {
    opacity: 0.9;
  }

  .cartoon-information {
    md-button {
      display: inline-block;
    }
  }

  .cartoon-icon {
    width: 60px;
  }

  .results {
    text-align: left;
  }

  .well,
  pre {
    background: none;
    border: none;
    font-size: 0.9em;
    color: #98a6ad;
  }

  .table>tbody>tr>th,
  .table>tfoot>tr>th,
  .table>tbody>tr>td,
  .table>tfoot>tr>td {
    padding: 8px 15px;
    border-top: none;
    color: #fff;
  }

  .table>thead>tr>th {
    padding: 8px 15px;
    border-bottom: 1px solid #eaeff0;
  }

  .table-bordered {
    border-color: #eaeff0;
  }

  .table-bordered>tbody>tr>td {
    border-color: #eaeff0;
  }

  .table-bordered>thead>tr>th {
    border-color: #eaeff0;
  }

  .table-striped>tbody>tr:nth-child(odd)>td,
  .table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #fafbfc;
  }

  .table-striped>thead>th {
    background-color: #fafbfc;
    // border-right: 1px solid #eaeff0;
  }

  .table-striped>thead>th:last-child {
    border-right: none;
  }

  .table {
    // width: 70%;
    --bs-table-striped-bg: #25af9b;
  }

  .divider {
    border: 1px dashed #7dcfc3;
  }

  .eclispe {
    position: absolute;
    position: absolute;
    border-radius: 50%;
    background: $mainBody-color;
    height: 55px;
    width: 30px;
  }

  .eclipse-right {
    left: -17px;
    top: 18%;
  }

  .eclipse-left {
    right: -17px;
    top: 18%;
  }

  .eclipse-top {
    top: 25%;
  }

  @media (max-width: 600px) {
    .eclipse-top {
      top: 14%;
    }

    .eclispe {
      width: 27px;
      height: 35px;
    }
  }
}
.google-auto-placed {
  display: none !important;
}
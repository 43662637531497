.article-view-wrapper {
    // z-index: 999;
    min-height: 90vh;
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 100px;

    .main-content {
        padding-top: 10px;
        h2 {
            font-size: 1.5em;
        }

        h3 {
            font-size: 1.2em;

        }

        h4 {
            margin-bottom: 20px;
        }

        a {
            font-weight: bold;
            color: #25AF9B;
        }
    }

    @media (max-width:600px) {
        padding: 20px;
    }
}

.article-card {
    width: 300px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border: 1px solid #E9E4DB;
    border-radius: 10px;
    padding-bottom: 10px;
    min-height: 330px;

    // img {
    //     object-fit: cover;
    //     height: 100%;
    // }

    span {
        display: block;
        text-align: center;
        background-color: $sidebar-color;
        width: fit-content;
        padding: 5px 10px;
        border-radius: 20px;
        margin: auto;
        color: white;
    }

    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
}

.article-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    li {
        list-style: none;
    }

    .text-box {
        padding: 0px 20px;
    }

    @media (max-width:600px) {
        padding: 0px;
        justify-content: center;
    }
}

.loader-wrapper {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.article-view-wrapper {

    table {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border-radius: 10px;
        margin: auto;

        thead {
            background: $calculator-green-color;
            color: #E9E4DB;

            th {
                padding: 5px;
                border-right: 1px solid #fff;
            }
        }

        td {
            border: 1px solid gray;
            padding: 5px;
        }
    }

    // article image stylying

    figure {
        img {
            max-width: 100%;
            object-fit: contain;
            height: 400px
        }
    }

}
.google-auto-placed {
    display: none !important;
  }